import '../css/returnPolicy.css';



function PrivacyPolicy() {
  return (
    <div className="policy_wrapper">
      <div className="policy_title">
        <b>PRIVACY POLICY</b>
      </div>
      
      <b>Effective Date</b>: March 28 2024
      <br></br>
      <br></br>
      <b>DISCLAIMER FOR CANADIAN USERS.</b>
      <br></br>
      <br></br>
      As defined under Canadian law, Personal Information means information about an identifiable individual (“Personal Information”). The disclosures mentioned herein are meant to transparently convey the methods of collecting, managing, storing, using, protecting, and sharing Personal Information by users (“Privacy Policy”). Users grant their consent to this Privacy Policy through it being readily available for viewing in accordance with the Personal Information Protection and Electronic Documents Act (“PIPEDA”). 
      <br></br>
      <br></br>
      <b>DISCLAIMER FOR EUROPEAN USERS.</b>
      <br></br>
      <br></br>
      We would like to make sure you are fully aware of all of your data protection rights. Every user is entitled to the following: 
      <br></br>
      <br></br>
      <b>The right to access</b> - You have the right to request our company for copies of your personal data. We may charge you a small fee for this service. 
      <br></br>
      <br></br>
      <b>The right to rectification</b> - You have the right to request that our company correct any information you believe is inaccurate. You also have the right to request our company to complete the information you believe is incomplete. 
      <br></br>
      <br></br>
      <b>The right to erasure</b> - You have the right to request that our company erase your personal data under certain conditions. 
      <br></br>
      <br></br>
      <b>The right to restrict processing</b> - You have the right to request that our company restrict the processing of your personal data under certain conditions. 
      <br></br>
      <br></br>
      <b>The right to object to processing</b> - You have the right to object to our company’s processing of your personal data under certain conditions. 
      <br></br>
      <br></br>
      <b>The right to data portability</b> - You have the right to request that our company transfer the data we have collected to another organization or directly to you under certain conditions. 
      <br></br>
      <br></br>
      If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us at our email: <a href="mailto:milavedevotion@gmail.com">milavedevotion@gmail.com</a>
      <br></br>
      <br></br>
      Call us at: 9088487766
      Or write to us: 733 summit st, Linden, New Jersey, 07036
      <br></br>
      <br></br>
      <b>DISCLAIMER FOR CALIFORNIA USERS.</b>
      <br></br>
      <br></br>
      Your privacy and rights under the California Consumer Privacy Act (CCPA) and the California Online Privacy Protection Act (CalOPPA) are important to us. We offer this document as a resource to view how we collect, manage, store, and use your Personal Information in the day-to-day running of our website. This Privacy Policy, intended for California residents, can be applied to all website users to disclose how we collect, manage, store, and use your Personal Information as defined under CIV 1798.140(v) of the California Consumer Privacy Act (CCPA).
      <br></br>
      <br></br>
      <b>THE BUSINESS.</b>
      <br></br>
      <br></br>
      This Privacy Policy is between you and the following organization:
      <br></br>
      <br></br>
      <u>Company Name</u>: Regni Gratia LLC
      <br></br>
      <u>Address</u>: 733 summit st, Linden, New Jersey, 07036
      <br></br>
      <u>Phone</u>: 9088487766
      <br></br>
      <u>E-Mail</u>: <a href="mailto:milavedevotion@gmail.com">milavedevotion@gmail.com</a>
      <br></br>
      <br></br>
      <b>Website.</b>
      <br></br>
      <br></br>
      <u>Website URL</u>: www.milavemaria.com 
      <br></br>
      <u>Website Name</u>: Milavemaria
      <br></br>
      <br></br>
      <b>PERSONAL INFORMATION COLLECTED.</b>
      <br></br>
      <br></br>
      In the past 12 months, we have or had the intention of collecting the following:
      <br></br>
      <br></br>
      <b>Identifiers</b>. A real name or alias, postal address, signature, home phone number or mobile phone number, bank account number, credit card number, debit card number or other financial information, physical characteristics or description, e-mail address; account name, Social Security Number (SSN), driver's license number or state identification card number, passport number, or other similar identifiers.
      <br></br>
      <br></br>
      <b>Commercial Information</b>. Records of personal property, products or services purchased, obtained, considered, or other purchasing or consuming histories or tendencies.
      <br></br>
      <br></br>
      <b>Inferences Drawn From Other Personal Information</b>. Profile reflecting a person’s preference, characteristics, psychological trends, predispositions, behavior, attitudes, intelligence, abilities, and aptitudes.
      <br></br>
      <br></br>
      Hereinafter known as “Personal Information.”
      <br></br>
      <br></br>
      You can set your browser not to accept cookies, and the above website tells you how to remove cookies from your browser. However, in a few cases, some of our website features may not function as a result.
      <br></br>
      <br></br>
      <b>ADVERTISEMENTS.</b>
      <br></br>
      <br></br>
      Our website does not show advertisements to users. This includes affiliate ads or any products and services offered by 3rd parties.
      <br></br>
      <br></br>
      <b>HOW WE USE PERSONAL INFORMATION.</b>
      <br></br>
      <br></br>
      We may use or disclose your Personal Information for the following purpose:
      <br></br>
      <br></br>
      <b>-Obligations</b>. To carry out any obligations for paid products, services, offerings, or any obligations under our terms and conditions or terms of use.
      <br></br>
      <b>-Feedback</b>. To get feedback on website improvements and generally provide an overall better experience.
      <br></br>
      <b>-Testing</b>. For testing, research, and analysis, of user behavior on the website.
      <br></br>
      <b>-Protection</b>. To protect against fraud, safeguard data, and the general security of the website.
      <br></br>
      <b>-Security</b>. To detect security incidents, verify human users, and avoid being subject to malicious, deceptive, fraudulent, or illegal activity.
      <br></br>
      <b>-Law Enforcement</b>. To respond to law enforcement requests as required by applicable law, court order, or governmental regulations.
      <br></br>
      <b>-Intended Purpose</b>. As described for the intended purpose when collecting your personal information.
      <br></br>
      <b>-Assessment</b>. To evaluate or conduct a merger, divestiture, restricting, reorganizing, dissolution, or outright sale, either wholly or partially, of our assets in which your Personal Information becomes a part of such sale.
      <br></br>
      <br></br>
      Our usage of your Personal Information may change over time, and when such changes occur, we will update this Privacy Policy accordingly.
      <br></br>
      <br></br>
      <b>SELLING PERSONAL INFORMATION.</b>
      <br></br>
      <br></br>
      Our policy is that we <b>DO NOT</b> sell your personal information. If this should change, you will be notified and this Privacy Policy will be updated.
      <br></br>
      <br></br>
      <b>SHARING PERSONAL INFORMATION.</b>
      <br></br>
      <br></br>
      We disclose your Personal Information to 3rd parties for business purposes. The general categories of 3rd parties that we share with are as follows:
      <br></br>
      <br></br>
      -Our 3rd party service providers that, without their services, our website would not be able to function in its current manner;
      <br></br>
      -Affiliated websites and businesses in an effort to bring you and our users improved services, products, and offerings;
      <br></br>
      -Other companies, affiliate partners, and 3rd parties that help us advertise products, services, and offerings to you, other users, and potential new customers;
      <br></br>
      -Third (3rd) parties to whom you, or an authorized agent on your behalf, authorized us to disclose your Personal Information;
      <br></br>
      -Third (3rd) parties or affiliates in connection with a corporate transaction, such as a sale, consolidation, or merger of our financial institution or affiliated business; and
      <br></br>
      -Other third (3rd) parties to comply with legal requirements or to disclose Personal Information to government authorities per the rule of law.
      <br></br>
      <br></br>
      In the last 12 months, it is recognized that we have disclosed the aforementioned categories of Personal Information for business purposes.
      <br></br>
      <br></br>
      <b>RIGHT AND CHOICES</b>.
      <br></br>
      <br></br>
      This Section describes your rights and choices regarding how we collect, share, use, and protect your Personal Information, how to exercise those rights, and limits and exceptions to your rights and choices.
      <br></br>
      <br></br>
      a.) <b>Exceptions</b>. The rights and choices in this Section do not apply to you if the information being collected is:
      <br></br>
      -Aggregate consumer information;
      <br></br>
      -Deidentified Personal Information; and
      <br></br>
      -Publicly available information.
      <br></br>
      <br></br>
      b.) <b>Access to Information</b>. If the above exceptions do not apply, and you have not made this request more than twice in a 12-month period, you have the right to request that we disclose certain information to you about our collection and use of your Personal Information over the past 12 months from the date we receive your request. Once we receive and confirm your request on your behalf, we will disclose it to you or your representative:
      <br></br>
      <br></br>
      -The categories of Personal Information we collect;
      <br></br>
      -The categories of sources for the Personal Information we collect;
      <br></br>
      -Our business or commercial purpose for collecting or selling such Personal Information;
      <br></br>
      -The categories of third parties to whom we sold or disclosed the category of Personal Information for a business or commercial purpose;
      <br></br>
      -The business or commercial purpose for which we sold or disclosed the category of Personal Information; and
      <br></br>
      -The specific pieces of Personal Information we collected about you in a form that you can take with you (also called a “Data Portability Request”).
      <br></br>
      <br></br>
      c.) <b>Deletion (Erasure) Request Rights</b>. You have the right to request that we delete any of your Personal Information that we collect from you and retain, subject to certain exceptions. Once we receive and verify your request, we will delete and direct our service providers to delete your Personal Information from our records unless an exception applies. We may deny your deletion request if retaining the Personal Information is necessary for us or our service providers to:
      <br></br>
      <br></br>
      -Complete the transaction for which we collected the Personal Information, provide a good or service that you requested, take actions reasonably anticipated within the context of our ongoing business relationship with you, or otherwise perform our contract with you;
      <br></br>
      -Detect security incidents, protect against malicious, deceptive, fraudulent, or illegal activity; or prosecute those for such activity;
      <br></br>
      -Debug to identify and repair errors that impair existing intended functionality;
      <br></br>
      -Exercise free speech, or exercise another right provided by law;
      <br></br>
      -Engage in public or peer-reviewed scientific, historical, or statistical research in the public interest that adheres to all other applicable ethics and privacy laws when the businesses' deletion of the Personal Information is likely to render impossible or seriously impair the achievement of such research if you previously provided informed consent.
      <br></br>
      -Enable solely internal and lawful uses of such Personal Information that are compatible with the context in which you provided it.
      <br></br>
      <br></br>
      d.) <b>Exercising Access, Data Portability, and Deletion Rights</b>. To exercise the access, data portability, deletion rights, or any other rights mentioned herein, a consumer or a consumer's authorized agent may submit a verifiable request to us by using the contact details mentioned herein.
      <br></br>
      <br></br>
      e.) <b>Requests</b>. You may only make a verifiable consumer request for access or data portability in relation to this Section. We cannot respond to your request or provide you with Personal Information if we cannot verify your identity or authority to make the request and confirm the Personal Information is related to you.
      <br></br>
      <br></br>
      Making a verifiable consumer request does not require you to create an account with us. We will only use Personal Information provided via a verifiable consumer request to verify the requestor's identity or authority to make the request.
      <br></br>
      <br></br>
      f.) <b>Authorized Agent</b>. An authorized agent is any person or legal entity registered with the Secretary of State or similar office that you have authorized to act on your behalf. If a request is made by an authorized agent acting on your behalf, we may require the following:
      <br></br>
      <br></br>
      -Submission of evidence by you with your permission for the authorized agent to submit a verifiable request on your behalf;
      <br></br>
      -For you to directly acknowledge, via electronic communication, that the authorized agent is allowed to act on your behalf;
      <br></br>
      -Require the authorized agent to verify their identity; or
      <br></br>
      -For a power of attorney document to be submitted that is signed in accordance with state law.
      <br></br>
      <br></br>
      We reserve the right to deny a request from an agent that does not submit proof that they have been authorized by you to act on your behalf and cannot verify their own identity to us.
      <br></br>
      <br></br>
      g.) <b>Response Timing and Format</b>. As required under privacy laws, we agree to respond to any verifiable consumer request within 45 days of its receipt. If we require more time, with a maximum of 90 days, we will inform you, in writing, of the reason. Such notification will be by e-mail unless there is another preferred communication method provided.
      <br></br>
      <br></br>
      If applicable, the response we provide will also explain the reasons we cannot comply with a request. For data portability requests, we will select a format to provide your Personal Information that is readily useable and should allow you to transmit the Personal Information from one entity to another entity without hindrance.
      <br></br>
      <br></br>
      No fee will be charged to process or respond to your verifiable consumer request.
      <br></br>
      <br></br>
      h.) <b>Right of Non-Discrimination</b>. We do not discriminate against you for exercising any of your rights in this Privacy Policy and under applicable laws. Unless permitted by law, we will not:
      <br></br>
      <br></br>
      -Deny you goods or services;
      <br></br>
      -Charge you different prices or rates for goods, services, and offerings, including through granting discounts or other benefits, imposing penalties; or
      <br></br>
      -Provide you with a different level of quality of goods or services.
      <br></br>
      <br></br>
      <b>CHANGES AND AMENDMENTS.</b>
      <br></br>
      <br></br>
      We reserve the right to amend this Privacy Policy at our discretion and at any time. When we make changes to this Privacy Policy, we agree to notify you by e-mail or other preferred communication methods.
      <br></br>
      <br></br>
      <b>LINKING TO 3RD PARTIES.</b>
      <br></br>
      <br></br>
      We may provide links to 3rd party sources such as websites, applications, content, or software (“3rd Parties”). When you use a link online to visit 3rd Parties, you will be subject to their privacy policy and the jurisdiction of governing law. It is recommended to familiarize yourself with its terms and disclosures regarding your Personal Information. We are not responsible for the handling of your Personal Information when using, accessing, or visiting 3rd Parties.
      <br></br>
      <br></br>
      <b>SECURITY & PROTECTION.</b>
      <br></br>
      <br></br>
      We use reasonable physical, electronic, and procedural safeguards that comply with federal standards to protect and limit access to Personal Information. This includes device safeguards used in accordance with industry standards.
      <br></br>
      It is understood by you that the Personal Information you submit to us electronically may not be secure when it is transmitted to us. Specifically, we recommend that you do not use unsecured or public channels to communicate sensitive or confidential information.
      <br></br>
      <br></br>
      <b>CONTACT.</b>
      <br></br>
      <br></br>
      If you have any questions or comments about this Privacy Policy, the ways in which we collect and use your Personal Information, your choices, or your rights regarding such use, or wish to exercise your rights, please do not hesitate to contact us by using the details mentioned in this Privacy Policy.
    </div>
  );
}

export default PrivacyPolicy;
