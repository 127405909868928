import '../css/content.css';
import StoreItems from './StoreItems';



function Content() {
  return (<>
    <div className='intro_descriptor'>
      <div>Welcome to our site,</div>
      The Reign of Grace icon is given to us in this 21st century to remind us that God      is still in command of the world and everything works in conformity with the purpose of                  His Will (eph 1:11). God the Father wants to show us that He is in control of our life, if we let Him.  Reign of Grace is a light leading us to God’s path and allowing its power to reign in and over us every single moment of our life. Let this ray of blessing enter, bathe and cover our household with the Lord’s Jesus Most Precious Blood.  His wisdom will overshadow us, His powerful Reign will sustain our faith, and His blessings will turn us into holy intercessors who renew a covenant with God daily through a life of prayers, fasting and silence. We will obtain from the Holy Spirit the virtue to bring His lights to others and we will witness how He will guide us through our sufferings and turn them into tidings of great joy. The power of the undivided Trinity will filter our inner most being: body, spirit and soul.
      <br></br>
      <br></br>
      We believe that by the power of the Holy Spirit, through the grace of the Virgin Mary, we have been wholly blessed with a Redeemer, a Eucharist and a Spiritual Manna. Mary has launched an invitation to humanity to redeem itself and return to the heavenly feast unbolt to us.  We must believe that by the power of the Trinity and the plenitude of grace given to Mary to share with all mankind, our life will be touched and changed for the better.  The Reign of Grace will allow the flame of love of God’s Kingdom to burn in our heart and receive the grace of an adopted child and share the love of the Mother of God who will cuddle us in her mantle and turn us into a one of her knee-partners.
      <br></br>
      <br></br>
      Becoming a prayer warrior is touching others’ lives through prayers: those in purgatory, our own ancestors, our love ones, relatives, our friends, our neighbors, our town, our country and the whole world. We will experience a change toward heavenly things and we will pass on the gift that was passed to us.  The Reign of Grace will be passed on to those we love, those who do not love us and those we do not even know, because our prayers will reach out to all of them through the power of God.
      <br></br>
      <br></br>
      The igloo of lights from the Holy Ghost manifested through our Celestial Mother in the only begotten Son of God, the Sacrificial Lamb will shower us with his Precious Blood. Even when we know in our heart that we are sinners, aren’t we all?
      <br></br>
      <br></br>
      “Where there is sin, grace overflows all the more.  Just as through disobedience of one man, the many were made sinners, so through the obedience of the one, the many will be made righteous; Come receive the abundance of grace and reign in life through Christ.” (Rom 5:17-21)
      <br></br>
      <br></br>
      <div>Please pray for the healing of your family trees, the only way to find your share of physical, mental and spiritual healing.</div>
      <div>
        Whoever you are reading these words, listen carefully:
        <br></br>
        humble yourself through prayers, curb your pride, and place it
        <br></br>
        under the drizzling rain of the Precious Blood of our Savior;
        <br></br>
        Let him take the lead in your life
      </div>
      
    </div>
    <div className='content'>
      <StoreItems />
    </div>
  </>);
}

export default Content;
