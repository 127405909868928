import React from 'react';
import '../css/topBar.css';

class TopTabs extends React.Component {



  render() {
    return (
      <div className='top_bar'>
        <div className='top_bar_container'>
          <a style={{color: 'black'}} href="/"><div className='logo'>MilaveMaria.com</div></a>
          <div className="tabs">
            <a href="/#store"><div className='tab'></div></a>
          </div>
          <div className="tabs">
            <a href="/works"><div className='tab'>Works</div></a>
          </div>
          <div className="tabs">
            <a href="/#store"><div className='tab'>Store</div></a>
          </div>
        </div>
      </div>
    );
  }
}

export default TopTabs;