import '../css/footer.css';

import { Link } from "react-router-dom";



function Footer() {
  return (
    <div className="footer_wrapper">
      <div className="footer_block">
        <a id="logo_link" href="/">
          <div className="logo">MilaveMaria.com</div>
        </a>
      </div>
      <div className="footer_block">
        <span>Contact:</span>
        <ul>
          <li><a href="mailto:milavedevotion@gmail.com">milavedevotion@gmail.com</a></li>
        </ul>
      </div>
      <div className="footer_block">
        <span>Legal:</span>
        <ul>
          <li><Link to="/terms">Terms and Conditions</Link></li>
          <li><Link to="/returnpolicy">Return Policy</Link></li>
          <li><Link to="/privacypolicy">Privacy Policy</Link></li>
        </ul>
      </div>
    </div>
  );
}

export default Footer;
