import '../css/returnPolicy.css';



function Terms() {
  return (
    <div className="policy_wrapper">
      <div className="policy_title">
        <b>TERMS AND CONDITIONS</b>
      </div>
      
      <b>Effective Date</b>: March 28 2024
      <br></br>
      <b>Last Updated</b>: March 28 2024
      <br></br>
      <br></br>
      <b>AGREEMENT TO TERMS</b>.
      <br></br>
      <br></br>
      The Terms of Use Agreement (“Agreement”), created on the effective date and last amended on date above, is made between you (“user,” “you” or “your”), and:
      <br></br>
      <br></br>
      <b>WEBSITE OWNER</b>.
      <br></br>
      <br></br>
      <u>Website URL</u>: www.milavemaria.com 
      <br></br>
      <u>Company Name</u>: Regni Gratia LLC :  
      <br></br>
      <u>Address</u>: 733 Summit st., Linden, New Jersey, 07036 
      <br></br>
      <u>E-Mail</u>: <a href="mailto:milavedevotion@gmail.com">milavedevotion@gmail.com</a> 
      <br></br>
      <u>Phone</u>: 9088487766
      <br></br>
      <br></br>
      The owner mentioned above includes its affiliated companies, websites, applications, software, and tools (the “Company,” “we,” “us,” or “our”), and, with this Agreement, outlines the rights and obligations that you and all users share, as well as any other media form, media channel, mobile website or mobile application related or connected thereto (collectively, the “website”), for the purpose of making use of any of the content, software, or other tools on the website (“services”).
      <br></br>
      <br></br>
      <b>PAYMENTS</b>. 
      <br></br>
      <br></br>
      All or a portion of the services offered by the Company on the website are paid in accordance with the terms below:
      <br></br>
      <br></br>
      <b>a.) Forms of Payment</b>. We accept payment through the methods offered at the time of purchase or when a balance is due. The provider and method of payment are determined by your location, device, and purchased item. We reserve the right, at any time, to reject payment for any reason. 
      <br></br>
      <b>b.) Currency</b>. Payments will be accepted on the website in the currency based on your location and in accordance with local laws.
      <br></br>
      <b>c.) Refund Policy</b>. Except when required by law, payments made by a user are not refundable by the Company. Refund requests are administered on a case-by-case basis and, if granted, do so at the sole discretion of the Company.
      <br></br>
      <b>d.) In-App Purchases</b>. If the website’s services are offered on an Android, iOS, or other mobile application (“mobile app”), this Agreement also applies to payments made on the mobile app. In addition, payments made on the mobile app must also be in accordance with the terms of the mobile app platform or “store."  
      <br></br>
      <br></br>
      <b>ACCESS</b>. 
      <br></br>
      <br></br>
      Your access to and use of the website and the services is conditional upon your acceptance of and compliance with this Agreement, which applies to all the website’s visitors. If for any reason, you do not agree with any of the terms of this Agreement, you may not access the website or its services.
      <br></br>
      <br></br>
      Additionally, your access to and use of the services is also conditional upon your acceptance of and compliance with our privacy policy which describes our policies and procedures on the collection, use, and disclosure of your personal information which is initiated by and commences when you use the website. The privacy policy discloses details and discloses your privacy rights and protections under applicable laws. It is advised to read our privacy policy prior to accessing the website or its services. 
      <br></br>
      <br></br>
      <b>a.) Minors (under the age of 18)</b>. 
      <br></br>
      <br></br>
      If any user is a minor in the jurisdiction where they reside, the minor must obtain permission from their parent or guardian to use the website. If a minor accesses the website, it is assumed that their parent or guardian has read and agrees to this Agreement and has given permission to the minor to use the website.
      <br></br>
      <br></br>
      <b>b.) Children (under the age of 13)</b>. 
      <br></br>
      <br></br>
      If any user is a child under the age of thirteen (13) years and from the United States, it is assumed that they have obtained permission and verifiable parental consent to use the website. Furthermore, this Agreement allows the protections mentioned under the Children’s Online Privacy Protection, specifically, 15 U.S. Code § 6502.
      <br></br>
      <br></br>
      We do not claim that the website's content is appropriate or suitable for you or any visitor. No information, data, or information provided on the website is intended for distribution or use in any location where such usage is prohibited or is contrary to any law or regulation which would subject the Company to the legal liability of any type. Any such access or use of the website is to be utilized on your own initiative, and you are solely responsible for any legal compliance.
      <br></br>
      <br></br>
      Any supplemental amendments or documents posted on the website following the effective date of this Agreement are to be considered expressly incorporated into this Agreement.
      <br></br>
      <br></br>
      <b>PROHIBITED ACTIVITIES</b>.
      <br></br>
      <br></br>
      As a user of our services, whether on the website or mobile app, it is prohibited to engage in the following activities to:
      <br></br>
      <br></br>
      -Systematically retrieve data or other content from the website or services to create or compile, directly or indirectly, a collection, compilation, database, or directory without our written permission;
      <br></br>
      -Trick, defraud, or mislead other users or us, especially in any attempt to learn sensitive account information such as user passwords;
      <br></br>
      -Circumvent, disable, or otherwise interfere with security-related features of the website or services, including those that restrict the copying of content or protected marks;
      <br></br>
      -Disparage, tarnish, or otherwise harm the Company, website, mobile app, or any other platforms where the services are offered;
      <br></br>
      -Use any information obtained from the website or the service to harass, abuse, or harm another person or group of people;
      <br></br>
      -Make improper use of our support services, specifically, our customer service representatives, or make false reports of abuse or misconduct;
      <br></br>
      -Use the website or services in a manner that is inconsistent with its intended use or against any applicable laws;
      <br></br>
      -Engage in spamming, linking, or referring to other websites for commercial or other purposes;
      <br></br>
      -Upload or transmit, or the attempt of such act, viruses, trojan horses, or other damaging or improper material, including the spamming or continuous posting of repetitive text, that has the potential to interfere, modify, impair, disrupt, alter, or interfere with another user’s experience with the website or its features, functions, operations, or maintenance;
      <br></br>
      -Attempting any unauthorized automated use of the website, such as using scripts to send comments and messages, or using mining tools with the intention of gathering, injecting, or extracting data;
      <br></br>
      -Deleting copyrights, trademarks, disclaimers, or any other marks from the website or its content;
      <br></br>
      -Impersonating another user or person by use of a username, e-mail, personal name, or in any other manner;
      <br></br>
      -Upload or transmit, or the attempt of such act, any material that acts as a passive or active information collection or transmission mechanism, including without limitation, clear graphics interchange formats (“gifs”), 1x1 pixels, web bugs, cookies, or other similar devices, also known as “spyware,” “passive collection mechanisms” or “pcms;”
      <br></br>
      -Interfering with, disrupting, or creating an undue burden on the website, services, networks, and other connections;
      <br></br>
      -Harassing, annoying, intimidating, or threatening any of the other users, employees, agents, contractors, or any other individual affiliated with the Company;
      <br></br>
      -Disabling or attempting to disable restrictions implemented by the website that prohibits access to certain areas;
      <br></br>
      -Copying or adapting the software of the website, including but not limited to Flash, PHP, HTML, JavaScript, or other code;
      <br></br>
      -Deciphering, decompiling, disassembling, or reverse engineering any of the software on the website;
      <br></br>
      -Except as may be the result of standard search engine or internet browser usage, to use, launch, develop, or distribute any automated system, including without limitation, any crawlable spider, robot or bots, cheat utility, scraper, or offline reader that accessed the website or services, or using or launching any unauthorized script or other software;
      <br></br>
      -Using a buying or purchasing agent to make purchases on the website;
      <br></br>
      -Making any unauthorized use of the website or the services, such as collecting usernames, e-mail addresses, or personal names of users by electronic or other means to send unsolicited e-mails or create user accounts by automated means or under false pretenses;
      <br></br>
      -Using the website or services as part of any effort to compete with us or otherwise using the website, services, marks, content, data, or any part thereof for any revenue-generating endeavor, commercial purpose, or for personal benefit;
      <br></br>
      -Using the website or services to advertise or offer to sell goods or other services;
      <br></br>
      -Selling your user profile or account on the website.
      <br></br>
      <br></br>
      <b>GUIDELINES FOR REVIEWS</b>.
      <br></br>
      <br></br>
      We may provide you, either on the website or by a 3rd party, the right to leave a review or rating of the services provided. Said review or rating requires that you:
      <br></br>
      <br></br>
      -Should have firsthand experience with the person/entity being reviewed; 
      <br></br>
      -Do not contain any offensive, abusive, racist, profanity-laden language;
      <br></br>
      -Do not reference discriminatory language or references related to religion, race, gender, national origin, age, marital status, sexual orientation, or disability;
      <br></br>
      -Do not include references to illegal activity;
      <br></br>
      -Do not post negative reviews as part of a scheme working with a competitor of ours;
      <br></br>
      -Do not make any suggestions or conclusions as to the legality of our services, products, or conduct;
      <br></br>
      -Do not post any false or misleading comments about your experience with the website or our Company; and
      <br></br>
      -Do not organize a campaign encouraging others to post reviews, whether positive or negative.
      <br></br>
      <br></br>
      In our sole discretion, we may decide to accept, reject, or remove reviews. Our responsibility is to screen reviews to ensure that any postings regarding the website and its services are accurate and verifiable. Any reviews made by you or other users of the website do not represent our opinions or statements of the services or of our affiliates or partners. We do not assume liability, claims, or losses resulting from any review made on the website. By posting a review, you hereby grant us a perpetual, nonexclusive, worldwide, commercial, royalty-free, and assignable license (and sublicense ability) to reproduce, modify, translate, transmit by any means, display, perform, and/or distribute all content relating to such reviews made.
      <br></br>
      <br></br>
      <b>COPYRIGHT POLICY</b>.
      <br></br>
      <br></br>
      <b>a.) Intellectual Property Infringement</b>.
      <br></br>
      <br></br>
      It is our duty to respect the intellectual property rights of others. Therefore, it is our policy to respond to any claim that infringes on any trademark, copyright, or other intellectual property protected under law.
      <br></br>
      <br></br>
      If you are an owner of any protected intellectual property that you feel is being used without your authorization, you must submit notice to us by any of the contact details mentioned herein and include a detailed description of the alleged infringement.
      <br></br>
      <br></br>
      If any request is made, and it is determined that you are not the owner of the intellectual property or do not have the authority to act on the owner’s behalf, you may be held accountable for damages which may also include costs related to attorneys’ fees for such misrepresentation.
      <br></br>
      <br></br>
      <b>b.) DMCA Notice and DMCA Procedure for Copyright Infringement Claims</b>.
      <br></br>
      <br></br>
      You may submit a notification pursuant to the Digital Millennium Copyright Act (DMCA) by providing our Company with the following information in writing (see 17 U.S.C 512(c)(3) for further detail):
      <br></br>
      <br></br>
      -An electronic or physical signature of the copyright owner or a person authorized to act on behalf of the owner of the copyrighted work;
      <br></br> 
      -A description of the copyrighted work that you claim has been infringed, including the URL(s) where the copyrighted work exists or a copy of the copyrighted work; 
      <br></br>
      -Your contact details including a personal name, address, phone number, and an e-mail address; 
      <br></br>
      -A statement that the copyright infringement is not authorized and that the request to remove the copyrighted work is in good faith; and 
      <br></br>
      -A statement by you, with language that includes “under penalty of perjury,” that the information included in the infringement removal is accurate.
      <br></br>
      <br></br>
      Upon receipt of a copyright infringement notification, we will take whatever steps are required to remove the copyrighted content from the website or the services.
      <br></br>
      <br></br>
      <b>INTELLECTUAL PROPERTY</b>.
      <br></br>
      <br></br>
      Except as otherwise indicated, all source coding, databases, functionalities, software, graphic designs, and media of any kind (e.g. audio, video, text, photos, etc.), content, trademarks, service marks, logos, and copyrights are considered to be intellectual and proprietary information (“intellectual property”). Such intellectual information is under our ownership and protected by local, state, national, and international laws and will be defended.
      <br></br>
      <br></br>
      No intellectual property is permitted to be copied, reproduced, aggregated, republished, uploaded, posted, publicly displayed, encoded, translated, transmitted, distributed, sold, licensed, or otherwise exploited for commercial purposes without our express prior written permission.
      <br></br>
      <br></br>
      <b>USER OBLIGATIONS</b>.
      <br></br>
      <br></br>
      You, as a user of the website or any of its services, agree to the following:
      <br></br>
      <br></br>
      -Any information used for registration purposes, if required, must be submitted in an accurate and completed manner; 
      <br></br>
      -If any information should change regarding your account, you agree to change it in a timely fashion; 
      <br></br>
      -You have the legal capacity to understand, agree with, and comply with this Agreement; 
      <br></br>
      -That you are not considered a minor in the jurisdiction where you reside or are accessing the website or its services; 
      <br></br>
      -That you will not access the website or its services through the use of bots, scripts, or any other use than the traditional manner as is intended; and 
      <br></br>
      -That you will use the website and its services in an authorized and legal manner in accordance with this Agreement.
      <br></br>
      <br></br>
      In regard to any of the information submitted by you, if it should be deemed inaccurate, out of date, or incomplete, we reserve the right to terminate your access to the website or account and any future intended use.
      <br></br>
      <br></br>
      <b>THIRD (3RD) PARTY WEBSITE AND CONTENT</b>.
      <br></br>
      <br></br>
      Our website or services may contain links to 3rd party websites or services that are not owned or controlled by us. Therefore, we assume no responsibility for the content, privacy policies, terms of use, practices, services, experiences, activities, or any other acts by 3rd parties. You acknowledge that if you are to be re-directed or forwarded to such 3rd party website, you hold us harmless and free of liability for any action that should occur on such websites, which may or may not include damages, losses, or any other claims.
      <br></br>
      <br></br>
      <b>SITE MANAGEMENT</b>.
      <br></br>
      <br></br>
      To ensure the best experience for all users of our website and services, we reserve the right, in our sole discretion, to do the following:
      <br></br>
      <br></br>
      -To monitor our website, services, and any other content for violations by users of this Agreement; 
      <br></br>
      -To take appropriate actions against our users, including legal action, for those who may have violated this Agreement or have attempted to defraud or cause harm to other users; 
      <br></br>
      -To refuse, restrict, limit, disable, or remove any and all files and Content which, due to excessive size limits or other properties, are burdensome to our systems or other users; and 
      <br></br>
      -To otherwise manage our website and services in such a way as to protect our rights and property and to encourage the optimal running of said websites and services.
      <br></br>
      <br></br>
      <b>PRIVACY POLICY</b>.
      <br></br>
      <br></br>
      Your access to and use of our website or services is conditional upon your acceptance of our privacy policy. Our privacy policy describes our rules and procedures on the collection, use, and disclosure of your personal information and details your privacy rights and how the law protects you and such data. It is recommended for all users to read to know their rights. Our privacy policy can be found at the following URL: www.milavemaria.com/privacypolicy
      <br></br>
      <br></br>
      We maintain the right to store certain data that you have transmitted by the use of the website or any of our services. You are solely responsible for the data you transmit and how it relates to any activity you have undertaken when using the website and any of its services. Therefore, you agree that we have no liability to you for any loss, breach, or corruption of any data and hereby waive any right of action against us that may or may not arise from such loss, breach, or corruption.
      <br></br>
      <br></br>
      <b>TERMINATION</b>.
      <br></br>
      <br></br>
      We may terminate or suspend your account for any reason and at our sole discretion. If your account is suspended or terminated, we may or may not provide prior notice. Upon termination, your access to the website and/or services will cease immediately.
      <br></br>
      <br></br>
      If you wish to terminate your relationship with us, such termination can be made by simply discontinuing your use of the website and its services.
      <br></br>
      <br></br>
      <b>GOVERNING LAW</b>.
      <br></br>
      <br></br>
      The laws governing the Company’s jurisdiction mentioned herein shall govern this Agreement, including your use and access to the website and services. Your use of this website, services, and any mobile app may be subject to other local, state, national, and international laws.
      <br></br>
      <br></br>
      <b>DISPUTE RESOLUTION</b>.
      <br></br>
      <br></br>
      If you should raise any dispute about the website, its content, or any of the services offered, it is required first to attempt to resolve the dispute formally by contacting us.
      <br></br>
      <br></br>
      <b>a.) Mediation</b>. If a dispute cannot be agreed upon by the parties, it shall be moved to mediation for a period of 30 days with at least 10 hours to be committed by each party in accordance with the procedures of the United States Arbitration & Mediation. All costs related to said mediation shall be shared equally by both parties.
      <br></br>
      <br></br>
      <b>b.) Arbitration</b>. If the dispute cannot be agreed upon during the mediation period, then the dispute will be submitted to binding arbitration in the jurisdiction of governing law.
      <br></br>
      <br></br>
      We maintain the right to bring proceedings regarding the substance of any dispute in the courts of the country where you or we reside.
      <br></br>
      <br></br>
      <b>”AS-IS” DISCLAIMER</b>.
      <br></br>
      <br></br>
      It is recognized to you, as a user of the website and any services offered, that they are provided on an “as-is,” “where is,” and “as available” basis, including faults and defects without warranty.
      <br></br>
      <br></br>
      To the maximum extent permitted under applicable law, the Company, on its own behalf and those of its affiliates, licensors, and service providers, expressly disclaim all warranties, whether express, implied, statutory, or otherwise, with respect to the said website and any services offered, including all implied warranties of merchantability, fitness for a particular purpose, title and non-infringement, and warranties that may arise out of the course of dealing, course of performance, usage or trade practice. Without limitation to the foregoing, we provide no warranty or undertaking, and make no representation of any kind, that the content or any services provided will meet your requirements, achieve any intended results, be compatible or work with any other software, applications, systems, devices, or services, including operating without interruption, or meet any performance or reliability standards or be error and bug-free from any defects that can or will be corrected.
      <br></br>
      <br></br>
      Without limiting the foregoing, neither we nor any of our providers make any representation or warranty of any kind, express or limited, in regard to the following:
      <br></br>
      <br></br>
      -The operation or availability of the website or any services, or the information content, and materials or products included herein; 
      <br></br>
      -The website or any services being uninterrupted or bug-free; 
      <br></br>
      -The accuracy, reliability, or currency of any information or content provided through the website or services; and 
      <br></br>
      -The website or any services, servers, content, or e-mails sent on behalf of our Company is free of viruses, scripts, trojan horses, worms, malware, timebombs, or any other harmful code.
      <br></br>
      <br></br>
      Some jurisdictions do not allow the exclusion of certain types of warranties or limitations on the applicable statutory rights of a consumer. Therefore, some or all of the above exclusions and limitations may not apply to you. The exclusions and limitations outlined in this section will be applied to the greatest extent under applicable law.
      <br></br>
      <br></br>
      <b>INDEMNIFICATION</b>.
      <br></br>
      <br></br>
      You agree to defend, indemnify, and hold us harmless, including any of our subsidiaries, agents, or affiliates and our respective officers, agents, partners, and employees, from and against any loss, damage, liability, claim, or demand, including reasonable attorneys’ fees and expenses, made by any 3rd party due to or arising out of the following:
      <br></br>
      <br></br>
      -Our content; 
      <br></br>
      -Use of the website or any of our services; 
      <br></br>
      -Not able to use the website or any of our services; 
      <br></br>
      -Any breach of this Agreement; 
      <br></br>
      -Any beach of representations and warranties set forth in this Agreement; 
      <br></br>
      -Any violation of the rights of a 3rd party, including but not limited to intellectual property rights; and 
      <br></br>
      -Any overt harmful act toward any other user of the website or its services.
      <br></br>
      <br></br>
      Notwithstanding the foregoing, we reserve the right, at your expense, to assume the exclusive defense and control of any matter for which you are required to indemnify us, and you agree to cooperate, at your expense, with our defense of such claims. We agree to use reasonable efforts to notify you of any such claim, action, or proceeding which is subject to this indemnification upon becoming aware of it.
      <br></br>
      <br></br>
      <b>NOTICES</b>.
      <br></br>
      <br></br>
      Except as explicitly stated otherwise, any notices sent to us must be sent to <a href="mailto:milavedevotion@gmail.com">milavedevotion@gmail.com</a>. Any notices sent to you regarding any communication that must be sent in accordance with this Agreement will be sent to the e-mail registered to any account created on the website.
      <br></br>
      <br></br>
      If notice is required to be sent via standard mail for legal or other purposes, the mailing address in Section 1 of this Agreement should be used.
      <br></br>
      <br></br>
      <b>ELECTRONIC MEANS</b>.
      <br></br>
      <br></br>
      When accessing the website or any of its services, sending e-mails, online forms, esignatures, or any type or kind of electronic records or communication, you consent that all agreements, notices, disclosures, and other communications we provide to you in such manner satisfies any legal requirement that such communication should be in writing. You hereby agree that the use of such electronic means will be regarded as sufficient and be viewed as the same as its physical counterpart. Furthermore, you hereby waive any rights or requirements under any statutes, regulations, rules, ordinances, or other laws in any jurisdiction which require an original signature or delivery or retention of non-electronic records.
      <br></br>
      <br></br>
      <b>CALIFORNIA USERS</b>.
      <br></br>
      <br></br>
      If any complaint with us is not satisfactorily resolved, you can contact the Complaint Assistance Unit of the Division of Consumer Services of the California Department of Consumer Affairs in any of the methods below:
      <br></br>
      <br></br>
      <b>Mail</b> 
      <br></br>
      1625 North Market Blvd, Suite N 112, 
      <br></br>
      Sacramento, California 
      <br></br>
      95834 
      <br></br>
      <br></br>
      <b>Telephone</b>
      <br></br>
      (800) 952-5210 
      <br></br>
      (916) 445-1254 
      <br></br>
      <br></br>
      <b>UNITED STATES FEDERAL GOVERNMENT END-USER PROVISIONS</b>.
      <br></br>
      <br></br>
      If you are a user acting on behalf of the U.S. federal government, our website and its services are treated as a “commercial item” as defined under 48 C.F.R. § 2.101.
      <br></br>
      <br></br>
      <b>EUROPEAN UNION (EU) USERS</b>.
      <br></br>
      <br></br>
      If you are a European Union (EU) resident, consumer, or user, it is recognized that you are entitled to specific protections on how your personal information is collected. We, in our privacy policy, attempt to be in accordance with such rules and regulations.
      <br></br>
      <br></br>
      <b>MISCELLANEOUS</b>.
      <br></br>
      <br></br>
      This Agreement and any policies or operating rules posted by us, on the website, or through any services or in respect to such constitute the entire Agreement and understanding between you, as a user, and us, as a Company. Our failure to exercise or enforce any right or provision of this Agreement will not operate as a waiver of such right or provision. This Agreement operates to the fullest extent permissible by law in accordance with the jurisdiction where we are located and to the protections that you, as a user, are entitled to in your jurisdiction. We reserve the right to assign any or all of our liabilities, services, and obligations to another party at any time. We shall not be responsible or liable for any loss, damage, delay, or failure to act caused by an event beyond our reasonable control.
      <br></br>
      <br></br>
      If any provision, section, clause, or part of this Agreement is determined to be unlawful, void, or unenforceable, that said portion of this Agreement is determined to be severable and does not affect the validity and enforceability of any remaining language.
      <br></br>
      <br></br>
      It is understood that this Agreement does not create a joint venture, partnership, employment, or agency relationship between you and us, the website, or any of its services. You agree that this Agreement will not be construed against us by virtue of having drafted and published on the website for your review. Therefore, you agree to waive any and all defenses that may have been assumed under this Agreement and the lack of signing by any party hereto.
      <br></br>
      <br></br>
      If this Agreement has been translated, you agree that its original English text shall prevail in the case of a dispute.
    </div>
  );
}

export default Terms;
