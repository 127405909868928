import '../css/storeItems.css';



function StoreItems() {

  let seeMoreOpen = false;
  function seeMore() {
    const element = document.getElementsByClassName("book_review")[0];
    const seeMoreElement = document.getElementsByClassName("see_more_btn")[0];
    if(seeMoreOpen) {
      element.style.height = "347px";
      element.style.boxShadow = "inset 0px -80px 60px -60px black, 3px 3px 7px #5b5b5b96";
      seeMoreElement.innerHTML = "See More ˅";
      seeMoreOpen = !seeMoreOpen;
    } else {
      element.style.height = "auto";
      element.style.boxShadow = "3px 3px 7px #5b5b5b96";
      seeMoreElement.innerHTML = "See Less ^";
      seeMoreOpen = !seeMoreOpen;
    }
  }

  return (<>
    <div id="store">
      <div className='book_review_wrapper'>
        <div className='store_item'>
          <stripe-buy-button
            buy-button-id="buy_btn_1P1dTOBJfmakW3DUzLphLgq6"
            publishable-key="pk_live_51P0t6JBJfmakW3DUjbUYfzmELNLiaciptw0e2LE263q7VXwr95Z8Hn2swiWIPq4HTPadQeoUyGPyi8VE6Kh4hcFW004eoeaC92"
          >
          </stripe-buy-button>
        </div>
        
        <div className='book_review' onClick={seeMore}>
          <p><a href="https://www.theusreview.com/reviews-1/Spiritual-Immunity-by-Carmen-G-Garcon.html">The U.S. Review of Books - Barbara Bamberger</a></p>
          <p>"Our responsibility is to remain under the umbrella of our Father in heaven."</p>
          <p>Author Garcon has created a resonant spiritual treatise comparing physical immunities to disease and other forces that assail the body to what she terms spiritual immunity (SI). This property allows one to anticipate and ward off feelings of sinfulness and self-destruction and, instead, follow God’s word and encourage others to do likewise. As a practicing nurse, Garcon observed the power of physical immunities, whether genetic or medically induced. Having explored this subject in depth, she explains how spiritual immunities can be passed on from our ancestral family and/or be developed as we pass through life’s adversities. She advises readers to explore their spiritual immunity history, tracing it through parents and grandparents when possible. If one’s forebears were strong in spirit, chances are that one will inherit their immunity to wrong thinking and wrongdoing.</p>
          <p>Her exploration, influenced by her strong Catholic faith, led her to develop a group she calls MilAve (1000 Prayers). The group has been meeting since 2008, regularly reciting the one thousand Hail Mary prayers together, a ritual of several hours. A significant portion of her book comprises testimonials from those who experienced miraculous encouragement and rewards from MilAve and its devotion to Mary, the mother of Jesus.</p>
          <p>Garcon was born in Haiti and, in her mid-twenties, emigrated to America, where she achieved a master’s degree in the science of nursing. Her concern for helping those in physical need alerted her to the possibilities of helping others and herself in the realm of spiritual growth. Her colorfully illustrated volume includes recommended daily prayers and the exaltation of the Holy Cross devotion, along with her engaging memoir-based chronicle of the MilAve foundation and gatherings. Garcon’s strong religious conviction and her proof that prayer recitation can generate positive experiences will form an attractive focus for like-minded groups and individual spiritual seekers.</p>
          <div className='see_more_btn'>See More ˅</div>
        </div>
      </div>


      <div className='mid_content_title'>All From Medjugorje</div>

      <div className='store_item'>
        <stripe-buy-button
          buy-button-id="buy_btn_1PFIiYBJfmakW3DUhUNJVLsJ"
          publishable-key="pk_live_51P0t6JBJfmakW3DUjbUYfzmELNLiaciptw0e2LE263q7VXwr95Z8Hn2swiWIPq4HTPadQeoUyGPyi8VE6Kh4hcFW004eoeaC92"
        >
        </stripe-buy-button>
      </div>
      <div className='store_item'>
        <stripe-buy-button
          buy-button-id="buy_btn_1PFIiuBJfmakW3DUjvBE2Kre"
          publishable-key="pk_live_51P0t6JBJfmakW3DUjbUYfzmELNLiaciptw0e2LE263q7VXwr95Z8Hn2swiWIPq4HTPadQeoUyGPyi8VE6Kh4hcFW004eoeaC92"
        >
        </stripe-buy-button>
      </div>
      <div className='store_item'>
        <stripe-buy-button
          buy-button-id="buy_btn_1PFIhMBJfmakW3DUEKfHXNpy"
          publishable-key="pk_live_51P0t6JBJfmakW3DUjbUYfzmELNLiaciptw0e2LE263q7VXwr95Z8Hn2swiWIPq4HTPadQeoUyGPyi8VE6Kh4hcFW004eoeaC92"
        >
        </stripe-buy-button>
      </div>
      <div className='store_item'>
        <stripe-buy-button
          buy-button-id="buy_btn_1P1dfqBJfmakW3DUkjrS3gp8"
          publishable-key="pk_live_51P0t6JBJfmakW3DUjbUYfzmELNLiaciptw0e2LE263q7VXwr95Z8Hn2swiWIPq4HTPadQeoUyGPyi8VE6Kh4hcFW004eoeaC92"
        >
        </stripe-buy-button>
      </div>
      <div className='store_item'>
        <stripe-buy-button
          buy-button-id="buy_btn_1P1dgUBJfmakW3DUkvA99y5u"
          publishable-key="pk_live_51P0t6JBJfmakW3DUjbUYfzmELNLiaciptw0e2LE263q7VXwr95Z8Hn2swiWIPq4HTPadQeoUyGPyi8VE6Kh4hcFW004eoeaC92"
        >
        </stripe-buy-button>
      </div>
      <div className='store_item'>
        <stripe-buy-button
          buy-button-id="buy_btn_1P1dglBJfmakW3DUypKV8puH"
          publishable-key="pk_live_51P0t6JBJfmakW3DUjbUYfzmELNLiaciptw0e2LE263q7VXwr95Z8Hn2swiWIPq4HTPadQeoUyGPyi8VE6Kh4hcFW004eoeaC92"
        >
        </stripe-buy-button>
      </div>
      <div className='store_item'>
        <stripe-buy-button
          buy-button-id="buy_btn_1P1dfQBJfmakW3DUS0G0z75X"
          publishable-key="pk_live_51P0t6JBJfmakW3DUjbUYfzmELNLiaciptw0e2LE263q7VXwr95Z8Hn2swiWIPq4HTPadQeoUyGPyi8VE6Kh4hcFW004eoeaC92"
        >
        </stripe-buy-button>
      </div>
      <div className='store_item'>
        <stripe-buy-button
          buy-button-id="buy_btn_1P1dgBBJfmakW3DUWvs3ohgI"
          publishable-key="pk_live_51P0t6JBJfmakW3DUjbUYfzmELNLiaciptw0e2LE263q7VXwr95Z8Hn2swiWIPq4HTPadQeoUyGPyi8VE6Kh4hcFW004eoeaC92"
        >
        </stripe-buy-button>
      </div>

      <div className='mid_content_title'>All From Medjugorje</div>

      <div className='store_item'>
        <stripe-buy-button
          buy-button-id="buy_btn_1PE1IOBJfmakW3DUWhIlJs0y"
          publishable-key="pk_live_51P0t6JBJfmakW3DUjbUYfzmELNLiaciptw0e2LE263q7VXwr95Z8Hn2swiWIPq4HTPadQeoUyGPyi8VE6Kh4hcFW004eoeaC92"
        >
        </stripe-buy-button>
      </div>
      <div className='store_item'>
        <stripe-buy-button
          buy-button-id="buy_btn_1PFTSmBJfmakW3DUuw7BPTtT"
          publishable-key="pk_live_51P0t6JBJfmakW3DUjbUYfzmELNLiaciptw0e2LE263q7VXwr95Z8Hn2swiWIPq4HTPadQeoUyGPyi8VE6Kh4hcFW004eoeaC92"
        >
        </stripe-buy-button>
      </div>
      <div className='store_item'>
        <stripe-buy-button
          buy-button-id="buy_btn_1PFTUuBJfmakW3DUNpxv05oj"
          publishable-key="pk_live_51P0t6JBJfmakW3DUjbUYfzmELNLiaciptw0e2LE263q7VXwr95Z8Hn2swiWIPq4HTPadQeoUyGPyi8VE6Kh4hcFW004eoeaC92"
        >
        </stripe-buy-button>
      </div>
      <div className='store_item'>
        <stripe-buy-button
          buy-button-id="buy_btn_1PLvzPBJfmakW3DUhKpuL1Mz"
          publishable-key="pk_live_51P0t6JBJfmakW3DUjbUYfzmELNLiaciptw0e2LE263q7VXwr95Z8Hn2swiWIPq4HTPadQeoUyGPyi8VE6Kh4hcFW004eoeaC92"
        >
        </stripe-buy-button>
      </div>
      <div className='store_item'>
        <stripe-buy-button
          buy-button-id="buy_btn_1PLvzEBJfmakW3DUfMHl1WNZ"
          publishable-key="pk_live_51P0t6JBJfmakW3DUjbUYfzmELNLiaciptw0e2LE263q7VXwr95Z8Hn2swiWIPq4HTPadQeoUyGPyi8VE6Kh4hcFW004eoeaC92"
        >
        </stripe-buy-button>
      </div>
      <div className='store_item'>
        <stripe-buy-button
          buy-button-id="buy_btn_1PLvz3BJfmakW3DUZZyphhjF"
          publishable-key="pk_live_51P0t6JBJfmakW3DUjbUYfzmELNLiaciptw0e2LE263q7VXwr95Z8Hn2swiWIPq4HTPadQeoUyGPyi8VE6Kh4hcFW004eoeaC92"
        >
        </stripe-buy-button>
      </div>
      <div className='store_item'>
        <stripe-buy-button
          buy-button-id="buy_btn_1PLvyoBJfmakW3DUwnanyXV8"
          publishable-key="pk_live_51P0t6JBJfmakW3DUjbUYfzmELNLiaciptw0e2LE263q7VXwr95Z8Hn2swiWIPq4HTPadQeoUyGPyi8VE6Kh4hcFW004eoeaC92"
        >
        </stripe-buy-button>
      </div>
      <div className='store_item'>
        <stripe-buy-button
          buy-button-id="buy_btn_1PLvydBJfmakW3DUCwzR3jNf"
          publishable-key="pk_live_51P0t6JBJfmakW3DUjbUYfzmELNLiaciptw0e2LE263q7VXwr95Z8Hn2swiWIPq4HTPadQeoUyGPyi8VE6Kh4hcFW004eoeaC92"
        >
        </stripe-buy-button>
      </div>
      <div className='store_item'>
        <stripe-buy-button
          buy-button-id="buy_btn_1PLvvRBJfmakW3DU5WtAieF0"
          publishable-key="pk_live_51P0t6JBJfmakW3DUjbUYfzmELNLiaciptw0e2LE263q7VXwr95Z8Hn2swiWIPq4HTPadQeoUyGPyi8VE6Kh4hcFW004eoeaC92"
        >
        </stripe-buy-button>
      </div>
      <div className='store_item'>
        <stripe-buy-button
          buy-button-id="buy_btn_1PRy0QBJfmakW3DUz99mWDb6"
          publishable-key="pk_live_51P0t6JBJfmakW3DUjbUYfzmELNLiaciptw0e2LE263q7VXwr95Z8Hn2swiWIPq4HTPadQeoUyGPyi8VE6Kh4hcFW004eoeaC92"
        >
        </stripe-buy-button>
      </div>
      <div className='store_item'>
        <stripe-buy-button
          buy-button-id="buy_btn_1PRy1VBJfmakW3DU2y8im2Uj"
          publishable-key="pk_live_51P0t6JBJfmakW3DUjbUYfzmELNLiaciptw0e2LE263q7VXwr95Z8Hn2swiWIPq4HTPadQeoUyGPyi8VE6Kh4hcFW004eoeaC92"
        >
        </stripe-buy-button>
      </div>
    </div>
  </>);
}

export default StoreItems;
