import '../css/intro.css';



function Intro() {
  return (
    <div className='intro_wrapper'>
      <div className='f_one'></div>
      <div className='f_one'></div>
      <div className='content_wrapper'>
        <img className='intro_pic' src="/pics/jesus1.jpg" alt=""></img>
      </div>
      <div className='f_one'></div>
    </div>
  );
}

export default Intro;
