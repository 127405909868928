import '../css/returnPolicy.css';



function ReturnPolicy() {
  return (
    <div className="policy_wrapper">
      <div className="policy_title">
        <b>ONLINE RETURN POLICY</b>
        <br></br>
        Effective as of: March 27 2024
      </div>

      <br></br>
      <br></br>
      <b>1. RETURNS.</b> We allow returns for refund only. We do not allow exchanges. For items purchased online, returns are accepted within 30 days of the delivery date. To initiate a return, please contact us at <a href="mailto:milavedevotion@gmail.com">milavedevotion@gmail.com</a> to obtain a return authorization. Be sure to include the item's order number and your reason for the return. Returns that are shipped without authorization may not be accepted. Please allow up to 10 business days for your refund to be processed once we receive your return.
      <br></br>
      <br></br>
      <b>2. RETURN SHIPPING.</b> Return authorization for items shipped in error, or items being returned/exchanged because they are defective or damaged, will include a pre-paid shipping label. Return shipping on items that are being returned/exchanged for other reasons will be paid for by the customer. 
      <br></br>
      <br></br>
      <b>3. ELIGIBLE ITEMS.</b> The following items:  Gift Cards are not eligible for return/exchange. We reserve the right to refuse any return/exchange, at management's discretion, if the item being returned/exchanged does not meet the criteria set forth within this policy.
      <br></br>
      <br></br>
      <b>4. CONDITION OF ITEMS.</b> Items may be in any condition.
      <br></br>
      <br></br>
      <b>5. FORM OF PAYMENT.</b> Refunds, if issued, will be issued in the original form of payment minus shipping and handling fees unless otherwise stated. If the original form of payment is unavailable, store credit may be issued at our discretion. 
      <br></br>
      <br></br>
      If you have any questions about this return policy, please contact us at <a href="mailto:milavedevotion@gmail.com">milavedevotion@gmail.com</a> 
    </div>
  );
}

export default ReturnPolicy;
