
import React from 'react';
import { Route, Routes} from "react-router-dom";

import TopTabs from './TopTabs';
import Home from './Home';
import ReturnPolicy from './ReturnPolicy';
import Terms from './Terms';
import PrivacyPolicy from './PrivacyPolicy';
import Footer from './Footer';
import Works from './Works';

import '../css/main.css';

class Main extends React.Component {
  
  componentDidMount() {
    
    setTimeout(() => {
      document.getElementsByClassName("main")[0].style.height = "100vh";
      setTimeout(() => {
        const elem = document.getElementsByClassName("intro_wrapper")[0];
        if(elem) {
          elem.style.opacity = 1;
        }
      }, 1000);
    }, 100);
  }

  render() {
    return (<>
      <div className='main'>
        <TopTabs />
        <Routes>
          <Route path="/*" element={<Home/>}/>
          <Route path="/terms" element={<Terms/>}/>
          <Route path="/returnpolicy" element={<ReturnPolicy/>}/>
          <Route path="/privacypolicy" element={<PrivacyPolicy/>}/>
          <Route path="/works" element={<Works/>}/>
        </Routes>
        <Footer />
      </div>
    </>);
  }
}

export default Main;
