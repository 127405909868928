import '../css/home.css';
import Intro from './Intro';
import Content from './Content';



function Home() {
  return (<>
    <Intro />
    <Content />
  </>);
}

export default Home;
